import { defineStore } from "pinia";
import { CartService } from "../services/cart.service";
import type { Cart } from "../types/cart";

export const useCartStore = defineStore("cart", {
  state: (): Cart => {
    return {
      cart: [],
    };
  },
  actions: {
    add(productId: string) {
      const cartService = new CartService();
      cartService.add(productId).then(() => {
        this.cart.push(productId);
      });
    },
    remove(productId: string) {
      const cartService = new CartService();
      cartService.delete(productId).then(() => {
        const index = this.cart.findIndex((c: string) => c === productId);
        if (index !== -1) {
          this.cart.splice(index, 1);
        }
      });
    },
    clear() {
      const cartService = new CartService();
      cartService.clear().then(() => {
        this.$reset();
      });
    },
    setCart(cart: string[]) {
      this.$state.cart = cart;
    },
  },
});
