export class CartService {
  add(productId: string): Promise<number[]> {
    return new Promise((resolve, reject) => {
      const cart = JSON.parse(localStorage.getItem("cart") ?? "null") || [];
      if (Array.isArray(cart)) {
        if (cart.findIndex((c) => productId === c) !== -1) {
          reject(new Error("Item already in cart"));
        }
        cart.push(productId);
        localStorage.setItem("cart", JSON.stringify(cart));
        resolve(cart);
      } else {
        reject(new Error("Cart unexpectedly not an array"));
      }
    });
  }

  delete(productId: string): Promise<number[]> {
    return new Promise((resolve, reject) => {
      const cart = JSON.parse(localStorage.getItem("cart") ?? "null") || [];
      if (Array.isArray(cart)) {
        const index = cart.findIndex((c) => productId === c);
        if (index === -1) {
          reject(new Error("Item not in cart"));
        }
        cart.splice(index, 1);
        localStorage.setItem("cart", JSON.stringify(cart));
        resolve(cart);
      } else {
        reject(new Error("Cart unexpectedly not an array"));
      }
    });
  }

  clear(): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.removeItem("cart");
      resolve();
    });
  }
}
